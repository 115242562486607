<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import { H3Error } from "h3";
import { FetchError } from "ofetch";
import { pushDataLayerEvent } from "~/lib/client-data-layer";
import nodeRenderers from "~/lib/node-renderers";
import salesforceCountries from "~/lib/salesforce/countries";
import salesforceSubTypes from "~/lib/salesforce/sub-types";
import type { TypeFormSignup } from "~/types/contentful";

const props = defineProps<{
  fields: TypeFormSignup<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const errorMessage = ref("");

// When true we display the success message
const submitted = ref(false);

// When true we disable the submit button
const pending = ref(false);

// Existing company that's being signed up to
const company = ref<string>();

// Whether we're registering a new company
const newCompany = ref(false);

// Search input for company name
const search = ref<HTMLFormElement>();

// Text input for company name
const companyName = ref<HTMLFormElement>();

const financialServicesOptions = [
  ...salesforceSubTypes,
  "Other (please specify)",
];

const financialServicesOther = ref(false);

// List of existing members to be offered when searching for a company
const { data: existingMembers } = await useLazyFetch<string[]>(
  "/api/signup/members",
  {
    transform: (data) =>
      data.filter(
        (memberName) =>
          !props.fields.excludeOrganisations?.includes(memberName),
      ),
  },
);

// Check if an email address is already registered
const emailExists = async (email: string) => {
  const data = await $fetch("/api/signup/check-email", {
    query: { email },
  });

  return data !== "ok";
};

// Fires the form data off to the endpoint
const signup = (event: Event) => {
  if (!ready.value || pending.value) {
    return;
  }

  errorMessage.value = "";

  const form = event.target as HTMLFormElement;

  Object.values(steps).forEach((step) => step.disabled = false);

  nextTick(() => {
    const formData = new FormData(form);

    pending.value = true;

    $fetch("/api/signup", {
      method: "POST",
      body: Object.fromEntries(formData.entries()),
    })
      .then((data) => {
        if (data === "ok") {
          submitted.value = true;

          pushDataLayerEvent("evSignup", {
            existingMember: !!formData.get("organisation_existing_member"),
          });
        } else {
          errorMessage.value = data.message;

          turnstile.value?.reset();

          Object.values(steps).forEach((step) => step.disabled = true);
        }

        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);

        pending.value = false;
      })
      .catch((error: unknown) => {
        if (error instanceof H3Error || error instanceof FetchError) {
          errorMessage.value = error.data.message;
        }

        Object.values(steps).forEach((step) => step.disabled = true);

        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);

        pending.value = false;

        turnstile.value?.reset();
      });
  });
};

// Whether the entire form is complete valid and therefore ready to submit
const ready = computed(
  () =>
    !Object.values(steps).filter(
      (step) => !step.completed || !step.valid,
    ).length && turnstileToken.value,
);

// This reactive object is used to track progress through the form
const steps = reactive<
  Record<
    string,
    {
      el: Element | ComponentPublicInstance | null;
      completed: boolean;
      disabled: boolean;
      activated: boolean;
      valid: boolean;
    }
  >
>({
  organisation: {
    el: null,
    completed: false,
    disabled: false,
    activated: !props.fields.forceOrganisation,
    valid: false,
  },
  user: {
    el: null,
    completed: false,
    disabled: false,
    activated: !!props.fields.forceOrganisation,
    valid: false,
  },
  legal: {
    el: null,
    completed: false,
    disabled: false,
    activated: false,
    valid: false,
  },
});

// Quick type guard for narrowing out ComponentPublicInstance
const isElement = (
  ref: Element | ComponentPublicInstance | null,
): ref is Element => !!(ref && !("el" in ref));

// Validates a step in order to establish if their respective complete button can be clicked
const validateStep = async (event: Event, key: string) => {
  if (!steps[key]) return;

  const el = steps[key].el;

  const input = event.target as HTMLFormElement | null;

  if (key === "organisation") {
    search.value?.setCustomValidity(
      !existingMembers.value?.includes(search.value.value) && !newCompany.value
        ? "Company name not found"
        : "",
    );

    companyName.value?.setCustomValidity(
      existingMembers.value?.includes(companyName.value.value)
      && newCompany.value
        ? "Company name already exists, please enter above"
        : "",
    );

    if (
      search.value?.checkValidity()
      && input?.getAttribute("type") === "search"
    ) {
      newCompany.value = false;
    }

    search.value?.classList.toggle(
      "invalid",
      // Only trigger a validation report if clicking away from the input
      !search.value.checkValidity(),
    );
  }

  if (key === "user") {
    if (input?.getAttribute("type") === "email" && input.value.includes("@")) {
      input.setCustomValidity(
        (await emailExists(input.value))
          ? "Email has already been registered"
          : "",
      );
    }
  }

  // Loop through all inputs in the step and filter by invalid, then set valid property to opposite of length
  if (isElement(el)) {
    steps[key].valid = !Array.from(el.querySelectorAll("input"))
      .reverse()
      .filter((input) => !input.checkValidity()).length;
  }

  // Only do something if we're clicking away from the input or if it's already marked invalid
  if (
    (event.type === "focusout" || input?.classList.contains("invalid"))
    && input?.checkValidity
  ) {
    input.classList.toggle("invalid", !input.checkValidity());
  }

  return steps[key].valid;
};

// Completes a step and activates the next
const completeStep = (_event: Event, key: string) => {
  if (!steps[key]) return;

  const stepEl = steps[key].el;

  if (isElement(stepEl)) {
    const invalid = stepEl.querySelector(":invalid");

    if (invalid) {
      (invalid as HTMLFormElement).reportValidity();

      return;
    }
  }

  steps[key].completed = true;

  steps[key].disabled = true;

  if (steps.user && key === "organisation") {
    steps.user.activated = true;
  }

  if (steps.legal && key === "user") {
    steps.legal.activated = true;
  }
};

// Catch links in rich text that will navigate us away and open them in a new window instead
const catchClick = (event: Event) => {
  if ((event.target as HTMLElement).nodeName === "A") {
    event.preventDefault();

    window.open((event.target as HTMLAnchorElement | null)?.href, "_blank");
  }
};

const selectCompany = (event: Event) => {
  const input = event.target as HTMLFormElement | null;

  if (input?.value.length > 2) {
    input?.setAttribute("list", input.dataset.list ?? "");
  } else {
    input?.setAttribute("list", "");
  }

  if (existingMembers.value?.includes(input?.value)) {
    company.value = input?.value;
  } else {
    company.value = "";
  }
};

const toggleNewCompany = (_event: Event, force: boolean | null = null) => {
  if (typeof force === "boolean") {
    newCompany.value = force;
  } else if (newCompany.value) {
    newCompany.value = false;
  } else {
    newCompany.value = true;
  }
};

const turnstile = ref();

const turnstileToken = ref("");

onMounted(() => setTimeout(() => {
  if (useScriptCloudflareTurnstile().$script.status === "error") {
    errorMessage.value = "Your browser does not support bot detection. Please whitelist challenges.cloudflare.com or try another browser.";
  }
}, 2000));
</script>

<template>
  <div class="my-5 sm:mx-4 md:my-6">
    <div
      v-if="submitted"
      class="mx-auto flex w-full max-w-[872px] flex-col gap-4 rounded-sm bg-level-standard px-3 py-4 md:px-8 md:py-5"
    >
      <RichTextRenderer
        :document="fields.successCopy"
        :node-renderers="nodeRenderers"
      />
    </div>
    <form
      v-else
      class="flex flex-col gap-[32px]"
      @submit.prevent="signup"
    >
      <Container class="mx-auto max-w-[872px]">
        <h1 class="pb-4 text-center">
          {{ fields.name }}
        </h1>
        <RichTextRenderer
          :document="fields.introCopy"
          :node-renderers="nodeRenderers"
        />
        <span
          v-if="errorMessage"
          class="error"
        >{{ errorMessage }}</span>
      </Container>
      <input
        v-if="fields.forceOrganisation"
        type="hidden"
        name="organisation_existing_member"
        :value="fields.forceOrganisation"
      />
      <div
        v-else-if="steps.organisation && steps.user"
        :ref="
          (ref: Element | ComponentPublicInstance | null) => {
            if (steps.organisation) {
              steps.organisation.el = ref;
            }
          }
        "
        class="mx-auto flex w-full max-w-[872px] flex-col gap-4 rounded-sm bg-level-standard px-3 py-4 md:px-8 md:py-5"
        :class="{ completed: steps.organisation.completed }"
        @input="(event: Event) => validateStep(event, 'organisation')"
        @focusout="(event: Event) => validateStep(event, 'organisation')"
      >
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-3 md:relative">
            <NuxtIcon
              v-if="steps.organisation.completed"
              name="fairr:tick"
              size="24"
              class="md:absolute md:-left-5"
            />
            <h4>{{ fields.stepOneTitle }}</h4>
          </div>
          <a
            v-if="steps.organisation.completed"
            href=""
            @click.prevent="steps.organisation.completed = false"
          >Edit</a>
        </div>
        <template v-if="steps.organisation.activated">
          <p>
            Search for your organisation below to determine if it is already
            part of the network. If you cannot find your organisation, please
            <a
              href=""
              @click.prevent="(event: Event) => toggleNewCompany(event, true)"
            >
              register your company
            </a>
            by filling out the details below.
          </p>
          <div class="find-company relative flex items-center">
            <NuxtIcon
              v-if="!steps.organisation.completed"
              class="absolute mx-2"
              size="24"
              name="fairr:search"
            />
            <input
              ref="search"
              type="search"
              data-list="existing-members"
              class="pl-[2.5rem]"
              placeholder="Find your organisation"
              aria-label="Find your organisation"
              :required="!newCompany"
              @input="selectCompany"
            />
            <datalist id="existing-members">
              <option
                v-for="member in existingMembers"
                :key="member"
                :value="member"
              />
            </datalist>
            <input
              v-if="company"
              type="hidden"
              name="organisation_existing_member"
              :value="company"
            />
          </div>
          <p v-if="!company">
            Not part of the network?
            <a
              href=""
              @click.prevent="(event: Event) => toggleNewCompany(event, true)"
            >Register your company</a>.
          </p>
          <div
            v-if="newCompany"
            class="grid grid-cols-2 gap-x-4 gap-y-3"
          >
            <div class="form-floating max-sm:col-span-2">
              <input
                id="company-name-input"
                ref="companyName"
                type="text"
                name="organisation_name"
                placeholder="Company Name*"
                aria-label="Company Name*"
                :disabled="steps.organisation.disabled"
                required
              />
              <label for="company-name-input">Company Name</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <input
                id="website-input"
                type="url"
                name="organisation_website"
                placeholder="Website*"
                aria-label="Website*"
                :disabled="steps.organisation.disabled"
                required
                @click="
                  (event) => {
                    if (
                      event.target
                      && (event.target as HTMLInputElement).value === ''
                    )
                      (event.target as HTMLInputElement).value = 'https://';
                  }
                "
              />
              <label for="website-input">Website</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <input
                id="aum-input"
                class="before:content-[$]"
                type="number"
                name="organisation_aum"
                placeholder="Approximate AUM/AUA (USD$)*"
                aria-label="Approximate AUM/AUA (USD$)*"
                :disabled="steps.organisation.disabled"
                required
              />
              <label for="aum-input">Approximate AUM/AUA (USD$)</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <input
                id="aum-date-input"
                type="date"
                name="organisation_aum_date"
                placeholder="Date AUM is Accurate to"
                aria-label="Date AUM is Accurate to"
                :disabled="steps.organisation.disabled"
              />
              <label for="aum-date-input">Date AUM is Accurate to</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <input
                id="aum-source-input"
                type="url"
                name="organisation_aum_source"
                placeholder="AUM Source"
                aria-label="AUM Source"
                :disabled="steps.organisation.disabled"
                @click="
                  (event) => {
                    if (
                      event.target
                      && (event.target as HTMLInputElement).value === ''
                    )
                      (event.target as HTMLInputElement).value = 'https://';
                  }
                "
              />
              <label for="aum-source-input">AUM Source</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <select
                id="hq-country-input"
                name="organisation_hq_country"
                placeholder="HQ Country*"
                aria-label="HQ Country*"
                :disabled="steps.organisation.disabled"
                required
              >
                <option
                  selected
                  disabled
                >
                  Please select
                </option>
                <option
                  v-for="country in salesforceCountries"
                  :key="country"
                  :value="country"
                >
                  {{ country }}
                </option>
              </select>
              <label for="hq-country-input">HQ Country</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <input
                id="hq-city-input"
                type="text"
                name="organisation_hq_city"
                placeholder="HQ City*"
                aria-label="HQ City*"
                :disabled="steps.organisation.disabled"
                required
              />
              <label for="hq-city-input">HQ City</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <select
                id="financial-services-input"
                name="financial_services"
                aria-label="What type of financial services do you provide?*"
                :disabled="steps.user.disabled"
                required
                @input="
                  (event: Event) =>
                    (financialServicesOther
                      = (event.target as HTMLSelectElement | null)?.value
                        === 'Other (please specify)')
                "
              >
                <option
                  selected
                  disabled
                  value=""
                >
                  Please select from the list
                </option>
                <option
                  v-for="financialService in financialServicesOptions"
                  :key="financialService"
                  :value="financialService"
                >
                  {{ financialService }}
                </option>
              </select>
              <label for="financial-services-input">
                What type of financial services do you provide?
              </label>
            </div>
            <div
              v-if="financialServicesOther"
              class="form-floating col-span-2"
            >
              <input
                id="financial-services-other-input"
                type="text"
                name="financial_services_other"
                placeholder="Financial Services*"
                aria-label="Financial Services*"
                :disabled="steps.user.disabled"
                required
              />
              <label for="financial-services-other-input">Financial Services</label>
            </div>
            <p class="col-span-2 mb-0 w-2/3">
              Is your organisation a subsidiary of another financial
              organisation? If yes, please state below.
            </p>
            <div class="form-floating max-sm:col-span-2">
              <input
                id="company-group-name-input"
                type="text"
                name="organisation_company_group_name"
                placeholder="Name of Parent Company"
                aria-label="Name of Parent Company"
                :disabled="steps.organisation.disabled"
              />
              <label for="company-name-input">Name of Parent Company</label>
            </div>
          </div>
          <button
            v-if="!steps.organisation.completed"
            class="btn btn-primary sm:w-fit"
            :class="{ disabled: !steps.organisation.valid }"
            @click="(event: Event) => completeStep(event, 'organisation')"
          >
            {{ fields.buttonOneCopy }}
          </button>
        </template>
      </div>

      <div
        v-if="steps.user"
        :ref="
          (el: Element | ComponentPublicInstance | null) => {
            if (steps.user) {
              steps.user.el = el;
            }
          }
        "
        class="mx-auto flex w-full max-w-[872px] flex-col gap-4 rounded-sm bg-level-standard px-3 py-4 md:px-8 md:py-5"
        :class="{ completed: steps.user.completed }"
        @input="(event: Event) => validateStep(event, 'user')"
        @focusout="(event: Event) => validateStep(event, 'user')"
      >
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-3 md:relative">
            <NuxtIcon
              v-if="steps.user.completed"
              name="fairr:tick"
              size="24"
              class="md:absolute md:-left-5"
            />
            <h4>{{ fields.stepTwoTitle }}</h4>
          </div>
          <a
            v-if="steps.user.completed"
            href=""
            @click.prevent="steps.user.completed = false"
          >Edit</a>
        </div>
        <template v-if="steps.user.activated">
          <div class="grid grid-cols-2 gap-x-4 gap-y-3">
            <div class="form-floating max-sm:col-span-2">
              <input
                id="first-name-input"
                type="text"
                name="user_first_name"
                placeholder="First Name*"
                aria-label="First Name*"
                :disabled="steps.user.disabled"
                required
              />
              <label for="first-name-input">First Name</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <input
                id="last-name-input"
                type="text"
                name="user_last_name"
                placeholder="Last Name*"
                aria-label="Last Name*"
                :disabled="steps.user.disabled"
                required
              />
              <label for="last-name-input">Last Name</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <input
                id="title-input"
                type="text"
                name="user_title"
                placeholder="Role*"
                aria-label="Role*"
                :disabled="steps.user.disabled"
                required
              />
              <label for="title-input">Role</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <input
                id="phone-input"
                type="tel"
                name="user_phone"
                placeholder="Telephone Number"
                aria-label="Telephone Number"
                :disabled="steps.user.disabled"
              />
              <label for="phone-input">Telephone Number</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <input
                id="email-input"
                type="email"
                name="user_email"
                placeholder="Work Email*"
                aria-label="Work Email*"
                :disabled="steps.user.disabled"
                required
              />
              <label for="email-input">Work Email</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <input
                id="password-input"
                type="password"
                name="user_password"
                placeholder="Password*"
                aria-label="Password*"
                :disabled="steps.user.disabled"
                required
              />
              <label for="password-input">Password</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <select
                id="user-country-input"
                name="user_country"
                placeholder="Country*"
                aria-label="Country*"
                :disabled="steps.user.disabled"
                required
              >
                <option
                  selected
                  disabled
                >
                  Please select
                </option>
                <option
                  v-for="country in salesforceCountries"
                  :key="country"
                  :value="country"
                >
                  {{ country }}
                </option>
              </select>
              <label for="user-country-input">Country</label>
            </div>
            <div class="form-floating max-sm:col-span-2">
              <input
                id="user-city-input"
                type="text"
                name="user_city"
                placeholder="City*"
                aria-label="City*"
                :disabled="steps.user.disabled"
                required
              />
              <label for="user-city-input">City</label>
            </div>
          </div>
          <button
            v-if="!steps.user.completed"
            class="btn btn-primary sm:w-fit"
            :class="{ disabled: !steps.user.valid }"
            @click.prevent="(event: Event) => completeStep(event, 'user')"
          >
            {{ fields.buttonTwoCopy }}
          </button>
        </template>
      </div>

      <div
        v-if="steps.legal"
        :ref="
          (el: Element | ComponentPublicInstance | null) => {
            if (steps.legal) {
              steps.legal.el = el;
            }
          }
        "
        class="mx-auto flex w-full max-w-[872px] flex-col gap-4 rounded-sm bg-level-standard px-3 py-4 md:px-8 md:py-5"
        :class="{ completed: steps.legal.completed }"
        @input="(event: Event) => validateStep(event, 'legal')"
        @focusout="(event: Event) => validateStep(event, 'legal')"
        @click="catchClick"
      >
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-3 md:relative">
            <NuxtIcon
              v-if="steps.legal.completed"
              name="fairr:tick"
              size="24"
              class="md:absolute md:-left-5"
            />
            <h4>{{ fields.stepThreeTitle }}</h4>
          </div>
          <a
            v-if="steps.legal.completed"
            href=""
            @click.prevent="steps.legal.completed = false"
          >Edit</a>
        </div>
        <template v-if="steps.legal.activated">
          <div
            v-if="fields.termsOfReference && !steps.legal.completed"
            class="wysiwyg max-h-[480px] overflow-y-auto rounded border border-ui-grey3 bg-white p-4"
          >
            <h1>{{ fields.termsOfReference?.fields.title }}</h1>
            <RichTextRenderer
              :document="fields.termsOfReference.fields.content"
              :node-renderers="nodeRenderers"
            />
          </div>
          <div class="grid">
            <UiCheckbox
              v-if="fields.checkboxOneCopy"
              id="legal-terms-input"
              name="legal_terms"
              :required="true"
              :disabled="steps.legal.disabled"
            >
              <RichTextRenderer
                :document="fields.checkboxOneCopy"
                :node-renderers="nodeRenderers"
              />
            </UiCheckbox>
          </div>
          <button
            v-if="!steps.legal.completed"
            class="btn btn-primary sm:w-fit"
            :class="{ disabled: !steps.legal.valid }"
            @click.prevent="(event: Event) => completeStep(event, 'legal')"
          >
            {{ fields.buttonThreeCopy }}
          </button>
        </template>
      </div>

      <div
        class="form-footer mx-auto flex w-full max-w-[872px] flex-col gap-4 px-3 md:px-8"
      >
        <RichTextRenderer
          v-if="fields.footerCopy"
          :document="fields.footerCopy"
          :node-renderers="nodeRenderers"
        />
        <NuxtTurnstile
          ref="turnstile"
          v-model="turnstileToken"
          :options="{
            'error-callback': (errorCode: string) => errorMessage = `Your browser failed bot detection, please try again or contact investoroutreach@fairr.org and quote error code ${errorCode}.`,
            'appearance': 'interaction-only',
          }"
        />
        <ClientOnly>
          <button
            class="btn btn-primary sm:w-fit"
            type="submit"
            :disabled="!turnstileToken || !ready || pending"
          >
            {{ pending ? "Please wait..." : fields.submitButtonCopy }}
          </button>
        </ClientOnly>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.wysiwyg {
  :deep(*) {
    @apply font-body;
  }
}

h4,
:deep(.checkbox p),
:deep(.footer p) {
  @apply mb-0;
}

.completed {
  .grid {
    @apply gap-y-0 max-sm:hidden;
  }

  input,
  select {
    @apply border-level-standard bg-transparent px-0;
  }

  :deep(p),
  :deep(input[type="checkbox"]),
  input:placeholder-shown + label,
  .wysiwyg {
    @apply hidden;
  }

  :deep(label) {
    @apply pl-0;

    p {
      @apply block;
    }
  }
}
</style>
